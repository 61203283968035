import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import indexVideomp4 from '../video/placeholder_1.mp4';
import Layout from "../components/layout/layout";

import { graphql } from 'gatsby';

import { Helmet } from 'react-helmet';

const Company = ({ data }) => {
    return (
        <Layout location="company" metadata={ { title: "会社案内｜株式会社テストサービス" } }>
            <main className="page--company page">
                <section className="section-company-jumbotron section-background-video--jumbotron section-background-video section-jumbotron section">
                    <div className="section-background-video__background-video section-jumbotron__background-overlay">
                        <video className="section-background-video__background-video-content" autoPlay muted loop>
                            <source src={ indexVideomp4 } type="video/mp4" />
                            { /* <source src="" type="video/webm" /> */ }
                            { /* <img src={ indexImage4.childImageSharp.fluid } title="Video content not supported" /> */ }
                        </video>
                    </div>
                    <div className="section-jumbotron__background-overlay">
                        <div className="section-jumbotron__text-box">
                            <h1 className="section-jumbotron__header">
                                <span className="section-jumbotron__header--main">会社案内</span>
                            </h1>
                        </div>
                    </div> 
                </section>
        <section className="section-company__list-data">
        <div class="compinfo">
            <h5>経営理念</h5>
            <hr class="txtcutter2" />
            <h6>使命</h6>
            <ul>
                <li>従業員とその家族が生き生きとする経営環境を創造する。</li>   
            </ul>
            
            <h6>試験業務</h6>
            <ul>
                <li>法令・規格を遵守し、厳正且つ公正な試験を実施し、信頼のある正しい結果を迅速かつ確実に提供する。</li>
            </ul>
            <h6>経営姿勢</h6>
            <ul>
                <li>顧客にたいして誠心誠意であること</li>   
                <li>顧客に信頼と満足が得られる試験機関であること</li>
                <li>品質管理を通して社会貢献に寄与すること</li>
                <li>常に努力と想像力を発揮して会社の発展に努めること</li>
            </ul>
            <br />
            <h5>品質管理に関する社長方針</h5>
            <hr class="txtcutter2" />
            <h6>目標</h6>
            <h6>代行業務</h6>
            <ul>
                <li>法令・規格を遵守し、公正且つ適合性のある検査を実施する。</li>   
                <li>品質の良い供試体の作成</li>
                <li>安全に徹した業務の遂行</li>
            </ul>
            
            <h6>試験業務</h6>
            <ul>
                <li>法令・規格を遵守し、厳正且つ公正な試験を実施し、信頼のある正しい結果を迅速かつ確実に提供する。</li>
                <li>顧客情報の守秘義務の遵守</li>
            </ul>
            <h6>目標達成のための方策</h6>
            <ul>
                <li>実務実施要領など業務基準の習熟</li>   
                <li>業務に必要な資格取得の奨励</li>
                <li>工具・機械、試験機器類のトレーサビリティの確保</li>
                <li>検査・試験精度の向上のための研鑚</li>
            </ul>
        </div>
        <br /><br />
        <div class="compinfo">
            <h5>会社概要</h5>
            <hr class="txtcutter2" />
            <div class="row">
                <div class="col lftcol">
                商号
                </div>
                <div class="col rtcol">
                株式会社 テストサービス
                </div>
            </div>
            <div class="row">
                    <div class="col lftcol">
                    所在地
                    </div>
                    <div class="col rtcol">
                    〒173-0024 東京都板橋区大山金井町47-12
                    </div>
                    </div>
            <div class="row">
                <div class="col lftcol">
                創立年月日
                </div>
                <div class="col rtcol">
                1973年8月15日
                </div>
            </div>
            <div class="row">
                <div class="col lftcol">
                資本金
                </div>
                <div class="col rtcol">
                1千万円
                </div>
            </div>
            <div class="row">
                <div class="col lftcol">
                代表取締役
                </div>
                <div class="col rtcol">
                奥平　昌道
                </div>
            </div>
            <div class="row">
                <div class="col lftcol">
                会社登録・加盟団体
                </div>
                  <div class="col rtcol">
                    <ul>
                        <li>東京都試験機関登録（Ａ類：普通強度、Ｂ類：高強度）</li>
                        <li>コンクリート採取試験会社登録</li>
                        <li>東京都検査機関登録（鉄筋継手）</li>
                        <li>優良鉄筋継手部検査会社認定</li>
                        <li>東京都建築材料試験連絡協議会会員（東試協）</li>
                        <li>東試協コンクリート採取試験会社協議会会員（東採協）</li>
                        <li>公益社団法人日本鉄筋継手協会会員</li>
                        <li>独立行政法人　製品評価技術基盤機構　ISO 17025 登録</li>
                    </ul>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="section-company__map-wrap-container">
                  <div className="section-company__map-wrap">
                      <div className="mapouter mapplace"><div className="gmap_canvas"><iframe width="600" height="513" id="gmap_canvas" src="https://maps.google.com/maps?q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%9D%BF%E6%A9%8B%E5%8C%BA%E5%A4%A7%E5%B1%B1%E9%87%91%E4%BA%95%E7%94%BA47-12&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.embedgooglemap.net/blog/20-off-discount-for-elegant-themes-divi-sale-coupon-code-2019/"></a></div></div>
                        <br />
                        <h6 >東京都板橋区大山金井町47-12</h6>
                        <br /><br />
                        <ul>
                                <li>熊野町交差点より川越街道下り（成増方面）4つ目の大山金井町交差点を右折して右側</li>
                                <li>東武東上線大山駅南口より徒歩約5分</li>
                        </ul>
                        <br /><br />
                  </div>
              </div>
                </section>
            </main>
        </Layout>
    )
}

export default Company;
